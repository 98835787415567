import React, {useState} from 'react';
import './App.css';
import 'cirrus-ui';
import logo from './logo.png';
import landingEmbed from './landing_embed.png';
import journeyLanding from './journey_landing.png';
import feedLanding from './feed_landing.png';
import updateLanding from './update_landing.png';
import skyeLanding from './skye_landing.png';
import Axios from 'axios';


function App() {
  const tiers = [
    {
      id: 'Visionary',
      symbol: "/images/Moon.webp",
      price: 'FREE',
      priceValue: 0,
      perks: [
        { text: 'SMART Goal Setting Aide', ready: true },
        { text: '1 Active Journey', ready: true },
        { text: 'Choice of 5 App Personalities for Reminders', ready: false },
        { text: 'Can Join Journey Communities', ready: false }
      ]
    },
    {
      id: 'Stargazer',
      symbol: "/images/MoonStars.webp",
      price: '$4.99 USD/month',
      priceValue: 499,
      perks: [
        { text: 'Unlimited Active Journeys', ready: true },
        { text: 'Automated To-do Lists', ready: true },
        { text: 'Custom Reminder Personalities', ready: false },
        { text: 'Can Create and Join Collaborative Journeys', ready: false },
      ]
    },
    {
      id: 'Dreamer',
      symbol: "/images/MoonStarsPlanets.webp",
      price: '$9.99 USD/month',
      priceValue: 999,
      perks: [
        { text: 'Everything in Stargazer', ready: true },
        { text: 'AI Companion Skye Daily Check-ins', ready: true },
        { text: 'Can Create and Manage Communities', ready: false },
      ]
    }
  ];

  const [message, setMessage] = useState("");
  const [sendState, setSendState] = useState(null)

    const send = async () => {
        const body = {
            message: message
        }
        try {
            await Axios.post("https://api.skyreachers.com/SendAppFeedbackEmail", body);
            setSendState(true)
        }
        catch (e) {
            console.log(e);
            setSendState(false)

        }
    }

  return (
    <div className="App">
      <header>
        <nav className="navbar">
          <div className="navbar-brand">
            <a href="https://skyreachers.com" className="navbar-brand-link nav-links u-center" style={{ display: "flex" }}>
              <img src={logo} alt="Skyreachers logo" style={{ height: '60px' }} />
              <div>Skyreachers</div>
            </a>
          </div>

          <div className="nav-links">
            <a href="#reach-for-the-stars">About Us</a>
            <a href="#pricing-section">Pricing</a>
            <a href="https://discord.gg/ySCpmvKY3A">Join our Discord</a>
            <a href="#contact-section">Contact Us</a>
          </div>

          <div className="auth-buttons">
            <a className="login" href="https://play.google.com/store/apps/details?id=com.skyreachers.twa&pcampaignid=web_share">Android</a>
            <a className="login" href="https://testflight.apple.com/join/extTJTTt?ref=producthunt">ios</a>
            <a className="login" href="https://app.skyreachers.com/auth/Login">Login</a>
            <a className="sign-up" href="https://app.skyreachers.com/auth/signup">Sign Up</a>
          </div>
        </nav>
        <div className="hero">
          <img src={landingEmbed} alt="Landing Image" className="hero-image" />
          <div className="hero-content">
            <h1>Combining the best of Humanity and AI.</h1>
            <p className="hero-subtitle">People progress in life by setting and achieving goals.</p>
            <p className="hero-subtitle">We are a group of technology professionals who believe that by sharing the journeys for achieving goals can make a difference in life trajectories.</p>
            <a href="https://app.skyreachers.com/auth/signup" className="scroll-link">Sign up to start!</a>
          </div>
        </div>
      </header>

      <section id="reach-for-the-stars" className="features">
        <div className="container">
          <h2>Ready to reach for the stars?</h2>
          <p className="features-intro">SkyReachers is a community-based goal setting platform, powered by AI to make the journey towards your goals the smoothest possible.</p>
          <img src={journeyLanding} alt="Journey Visualization" className="features-image" />
        </div>
      </section>

      <section className="peer-journeys" id="discover">
        <div className="container">
          <div className="text-side">
            <h2>Discover the journeys of your peers</h2>
            <p>Start by exploring communities and discover the journeys your peers are completing, and get inspired to start your own!</p>
          </div>
          <div className="image-side">
            <img src={feedLanding} alt="Feed Visualization" className="feed-image" />
          </div>
        </div>
      </section>

      <section className="generate-journeys">
        <div className="container">
          <div className="image-side">
            <img src={updateLanding} alt="Update Visualization" className="update-image" />

          </div>
          <div className="text-side">
            <h2>Generate journeys and post your updates</h2>
            <p>Publish updates of your achievements and store them as part of your journey.</p>
          </div>
        </div>
      </section>


      <section className="ai-assistant">
        <div className="container">
          <div className="text-side">
            <h2>Stay accountable with the help of Skye, AI-executive assistant</h2>
            <p>Integrate your calendar and stay on track with your goals with the help of Skye.</p>
          </div>
          <div className="image-side">
            <img src={skyeLanding} alt="Skye AI Assistant" className="ai-assistant-image" />

          </div>
        </div>
      </section>

      <section id="pricing-section" className="pricing">
        <div className="container">
          <h2 style={{ marginLeft: "12px" }}>Pricing</h2>
          <div className="pricing-tier">
            <div className="tier-wrapper">
              <div className="tier-container">
                {tiers.map((tier) => (
                  <div key={tier.id} className="tier">
                    <h3>{tier.id}</h3>
                    <img style={{ width: "150px", marginTop: "-24px", marginBottom: "-20px" }} src={process.env.REACT_APP_CLOUDFRONT + tier.symbol}></img>
                    <div><b>{tier.price}</b></div>
                    <ul className="tier-list">
                      {tier.perks.map((perk, index) => (
                        <div style={{ display: "flex" }}>
                          <div style={{ width: "25px" }}>
                            <span className="fa fa-star" style={{ color: "#FFBB8C" }} />
                          </div>
                          <li className={`tier-li ${perk.ready ? "" : "tier-unready"}`} style={{ listStyle: "none" }} key={index}>{perk.text}{perk.ready ? "" : "*"}</li>
                        </div>
                      ))}
                    </ul>
                    <div style={{ fontSize: "x-small", marginTop: "-10px", paddingBottom: "10px" }}>
                      *These features will be coming soon.
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="contact-section" className="contact">
        <div className="card u-center">
          <div className="card-internal-header" style={{ marginTop: "24px" }}>
            <h5>Contact the SkyReachers Team</h5>
          </div>
          <div className="u-text-center pt-3 contact-font" style={{ width: "100%" }}>
            The easiest way to contact the SkyReachers Team is through our discord server.
            <br />
            There we will endeavour to quickly respond to any feedback or bug reports.
            <br />
            <div className="u-center" style={{ margin: "12px" }}>
              <a href="https://discord.gg/MCxrJEp5Av" target="_blank" rel="noopener noreferrer">
                <button className="outline" style={{ backgroundColor: "#404eed", color: "white" }}>Join Our Discord<span><i className="fab fa-discord fa-lg" /></span></button>
              </a>
            </div>
            We really want to build something that helps people improve their lives but we can't do that without your help and feedback.
            <br />
            Otherwise, feel free to use the textbox below and we'll recieve the message!
            <br />
          </div>
          <div style={{ width: "100%" }} className="u-center">
            <textarea value={message} style={{ width: "95%" }} onChange={(e) => setMessage(e.target.value)}></textarea>
          </div>
          <div className='u-center' style={{ marginBottom: "24px", width: "100%" }}>
            <div>
              <button className="send-button" onClick={() => send()}>Send</button>
            </div>
          </div>
          <div className="u-center" style={{ marginTop: "-24px", marginBottom: "24px", width:"100%"}}>
            {sendState !== null ? (sendState ?
              <div className="field-error field-success">
                Message sent successfully
              </div>
              :
              <div className="field-error">
                Something went wrong and the message was not delivered.
                Please directly email support@skyreachers.com.
              </div>
            ) : ""
            }
          </div>
          <div>
            <b>For any requests to delete user data, such as personal accounts, please email support@skyreachers.com directly.</b>
            <br/>
            <b>After account ownership verification, any requested data will be deleted immediately.</b>
            <br/>
          </div>
        </div>
    </section >

    <footer className="footer" style={{ padding: "1rem" }}>
      <div className="container">
        <div className="footer-content">
          <div className="footer-brand">
            <p>&copy; 2023 Skyreachers Inc. All rights reserved</p>
          </div>
        </div>
      </div>
    </footer>
    </div >
  );
}

export default App;